<template>
  <div>
    <div class="container rounded shadow carts mt-2">
      <div class="row px-5 py-4">
        <h2 class="py-3">Hakkımızda:</h2>
      </div>
      <div class="row px-4 py-4 resimler">
        <img src="./images/carousel1.svg" alt="" />
        <img class="d-none d-md-block" src="./images/carousel2.svg" alt="" />
        <img class="d-none d-xl-block" src="./images/carousel3.svg" alt="" />
      </div>
      <div class="row px-5 py-4">
        <p class="py-3">
          2003 yılında şahıs otaklığı, 2005 yılında ise limited şirket olarak
          yoluna devam etme kararı alan firmamız, inşaat ve sanayi sektörlerinin
          ihtiyacı olan elektrik ve zayıf akım sistemlerinin ( Kartlı Geçiş,
          Yangın İhbar , Dahili Alarm , CCTV, IP Kamera ve Akıllı Bina
          Teknolojileri ) entegrasyonunu tek elden sağlayabilecek bir firma
          olabilmek amacıyla yola koyulmuştur. Geçen zaman içinde yurt içinde ve
          yurt dışında bitirdiğimiz projeler , hedefimize ulaşmada adım adım
          ilerlediğimizi göstermektedir.
        </p>
        <p class="py-3">
          <b>Vizyonumuz</b> , edinmiş olduğumuz bilgi birikimi ve tecrübelerimiz
          ışığında sektöre yön verecek firmaların arasında olabilmektir.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pose",
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem;
  color: rgba($color: #164098, $alpha: 0.9);
  border-top: rgba($color: #164098, $alpha: 0.87) 4px solid;

  img {
    width: 100%;
  }

  .row {
    p {
      color: rgba($color: #164098, $alpha: 0.75);
      font-size: 1.1em;
    }
  }
}

.carts {
  background: white;
}

@media screen and (min-width: 768px) {
  .carts {
    transform: translate(0, -100px);
  }
}
.resimler {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: auto;
  }
}
.splide-m {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: 1rem;
  }
}
</style>
