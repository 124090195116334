<template>
  <div class="mt-5 footer">
    <div class="layer pt-5 pb-5">
      <div class="container ">
        <div class="row d-flex align-items-start">
          <div class="col-lg-5 col-xs-12 about-company">
            <h2>Gence Mühendislik</h2>
            <p class="pr-5 text-white-50">Y.Dudullu Mah. Egemen Sok. No:6/A 34775 Ümraniye/İstanbul</p>
            <a href="tel:+902165264853" class="mb-0">0(216) 526 48 53/54</a>
            <p>info@gencemuhendislik.com.tr</p>
          </div>
          <div class="col-lg-3 col-xs-12 links">
            <!--<h4 class="mt-lg-0 my-sm-3">Hızlı Linkler</h4>
              <ul class="m-0 p-0">
              </ul>-->
          </div>
          <div class="col-lg-4 col-xs-12 location">
            <h4>Lokasyonumuz</h4>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.4487349753376!2d29.151835715374453!3d41.01543757930004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac89640b0eee9%3A0xf6df2df1db2da511!2sGence%20M%C3%BChendislik%20San.%20ve%20Tic.%20Ltd.%C5%9Eti.!5e0!3m2!1str!2str!4v1600259324588!5m2!1str!2str" width="90%" height="90%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col copyright">
            <p class=""><small class="text-white-50">© 2020. All Rights Reserved.</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer{
    background: url("./background.jpg") no-repeat fixed center;
    .layer{
      background: rgba(11, 59, 104, 0.85);
    }
    color:white;
    
    .links{
      ul {list-style-type: none;}
      li a{
        color: white;
        transition: color .2s;
        text-decoration:none;
        &:hover{
          color: #4180cb;
          }
      }
    }  
    .about-company{
      i{font-size: 25px;}
      a{
        color:white;
        transition: color .2s;
        &:hover{color:#4180CB}
      }
    } 
    .location{
      i{font-size: 18px;}
    }
    .copyright p{border-top:1px solid rgba(255,255,255,.1);} 
  }
</style>