<template>
  <div class="container rounded shadow carts mt-2">
    <div class="row px-4 py-4">
      <h2 class="py-2 px-4">Sertifikalarımız:</h2>
      <splide class="splide-m" :options="options">
        <splide-slide>
          <img src="./images/sertifika-1.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-3.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-2.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-4.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-5.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-6.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-7.jpg" alt="" />
        </splide-slide>
        <splide-slide>
          <img src="./images/sertifika-8.jpg" alt="" />
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
export default {
  name: "Certificets",
  data() {
    return {
      options: {
        type: "loop",
        autoWidth: true,
        autoplay: true,
        focus: "center",
        pagination: false,
        arrows: false,
        perPage: 3,
        breakpoints: {
          1024: {
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem;
  color: rgba($color: #164098, $alpha: 0.9);
  border-top: rgba($color: #164098, $alpha: 0.87) 4px solid;

  img {
    width: 100%;
  }

  .row {
    p {
      color: rgba($color: #164098, $alpha: 0.75);
      font-size: 1.1em;
    }
  }
}

.carts {
  background: white;
}

@media screen and (min-width: 768px) {
  .carts {
    transform: translate(0, -100px);
  }
}
.resimler {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: auto;
  }
}
.splide-m {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: 1rem;
  }
}
</style>