<template>
  <div id="referances">
    <splide class="splide-m" :options="options">
      <splide-slide>
        <img src="./images/590500184800.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/61VaoHj7IbL._SX425_.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/azenet.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/baseak.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/eca.gif" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/emas.gif" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/eski çeşme.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/flo.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/godoor medical center.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/ibb_logo.gif" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/instreet.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/ist otobus as.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/istanbul konut.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/koçaslanlar.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/kvklogo.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/lexmark.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/magnificotel.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/mapa.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/mvholding.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/ninewest.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/oyak-insaat.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/Renault-logo-2015-2048x2048.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/sifaslogo.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/sümer varlık.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/turanlilarlogo.jpg" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/verem savaş .png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/volvo-logo.png" alt="" />
      </splide-slide>
      <splide-slide>
        <img src="./images/çalışkan lojistik.jpg" alt="" />
      </splide-slide>
    </splide>
  </div>
</template>
28
<script>
export default {
  name: "SplideBody",

  data() {
    return {
      options: {
        type: "loop",
        autoWidth: true,
        autoplay: true,
        focus: "center",
        pagination:false,
        arrows:false,
        perPage: 6,
        breakpoints: {
          "640": {
            perPage: 2,
          },
          "480": {
            perPage: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#referances {
  .splide-m {
    img {
      height: 50px;
      object-fit: scale-down;
      margin: 1rem;
    }
  }
}
</style>
