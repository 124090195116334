<template>
  <div id="app">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
};
</script>
<style>
/*      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>*/
</style>
