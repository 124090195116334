<template>
  <div class="container rounded px-5 shadow carts mt-4">
    <h2 class="py-5">Galeri:</h2>
    <div class="row">
      <div class="col-12 col-md-4 py-5">
        <router-link to="/ref-gallery">
          <img src="./images/polaroid.svg" alt="" class="w-100" />
        </router-link>
      </div>
      <div class="col-12 col-md-8 py-5">
        <splide class="splide-m" :options="options">
          <splide-slide>
            <img src="./images/referans-1.jpg" alt="" />
          </splide-slide>
          <splide-slide>
            <img src="./images/referans-3.jpg" alt="" />
          </splide-slide>
          <splide-slide>
            <img src="./images/referans-2.jpg" alt="" />
          </splide-slide>
          <splide-slide>
            <img src="./images/referans-4.jpg" alt="" />
          </splide-slide>
          <splide-slide>
            <img src="./images/referans-5.jpg" alt="" />
          </splide-slide>
          <splide-slide>
            <img src="./images/referans-6.jpg" alt="" />
          </splide-slide>
        </splide>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferanceGallery",
  data() {
    return {
      options: {
        type: "loop",
        autoWidth: true,
        autoplay: true,
        focus: "center",
        pagination: false,
        arrows: false,
        perPage: 3,
        breakpoints: {
          1024: {
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem;
  color: rgba($color: #164098, $alpha: 0.9);
  border-top: rgba($color: #164098, $alpha: 0.87) 4px solid;

  img {
    width: 100%;
  }

  .row {
    p {
      color: rgba($color: #164098, $alpha: 0.75);
      font-size: 1.1em;
    }
  }
}

.carts {
  background: white;
}

@media screen and (min-width: 768px) {
  .carts {
    transform: translate(0, -100px);
  }
}
.resimler {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: auto;
  }
}
.splide-m {
  width: 100%;
  height: 280px;
  margin: auto;
  img {
    height: 280px;
    width: auto;
    //object-fit: scale-down;
    margin: 1rem;
  }
}

.elec-div,
.sec-div {
  h5 {
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
</style>