<template>
  <div class="Navbar-Wrapper shadow-sm">
    <b-navbar toggleable="lg" variant="light">
      <b-navbar-brand>
        <router-link to="/">
          <img src="./logo.svg" class="" loading="lazy" />
          <b>Gence</b><span>Mühendislik</span>
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link to="/"> Ana Sayfa </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/solutions"> Çözümlerimiz </router-link>
          </b-nav-item>
          <b-nav-item-dropdown text="Referanslarımız" id="ref" left>
            <b-dropdown-item>
              <router-link to="/ref-elec">
                Elektrik Referanslarımız
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link to="/ref-sec">
                Güvenlik Referanslarımız
              </router-link>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>
              <router-link to="/ref-gallery"> Referans Galerisi </router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item>
            <router-link to="/contact">İletişim</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  mounted() {
    console.log(
      `At this point, vm.$el has been created and el has been replaced.`
    );
    this.$el.querySelector("#ref__BV_toggle_ > span").style.color="rgba(22, 64, 152,.65)"; 
    console.log(this.$el.querySelector("#ref__BV_toggle_ > span").attributes) 
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
}
nav {
  font-size: 1rem;

  .navbar-brand {
    display: flex;
    align-items: flex-end;

    @media (min-width: 320px) {
      font-size: 1.1rem;
    }
    @media (min-width: 370px) {
      font-size: 1.5rem;
    }
    @media (min-width: 500px) {
      font-size: 1.6rem;
    }
    b {
      color: rgba($color: #164098, $alpha: 1) !important;
    }
    span {
      color: rgba($color: #164098, $alpha: 0.75) !important;
      padding-left: 0.4rem;
    }
    img {
      align-self: flex-start;
      @media (min-width: 425px) {
        width: 60px;
      }
      width: 40px;
      margin-right: 0.5rem;
    }
  }
  ul {
    li {
      border-radius: 4px;
      padding: 0.5rem;
      &:hover {
        transition: all ease-in-out 0.6s;
        background-color: rgba(240, 240, 240, 0.7);
      }
      a {
        color: rgba($color: #164098, $alpha: 0.65) !important;
      }
      .active {
        color: rgba($color: #164098, $alpha: 0.87) !important;
      }
    }
  }
  .dropdown-item {
    cursor: pointer;
  }
}
</style>
