<template>
  <div style="position: relative">
    <b-carousel
      class="shadow"
      id="carousel-1"
      v-model="slide"
      :interval="3000"
      background="transparent"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide>
        <template #img>
          <img
            class="carousel-image-m"
            src="./images/pawel-czerwinski-zBTYRFCeaS0-unsplash.jpg"
            alt=""
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="carousel-image-m"
            src="./images/american-public-power-association-TF-DL_2L1JM-unsplash.jpg"
            alt=""
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="carousel-image-m"
            src="./images/pexels-pixabay-271667.jpg"
            alt=""
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img class="carousel-image-m" src="./images/caro-5.jpg" alt="" />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img class="carousel-image-m" src="./images/caro-6.jpg" alt="" />
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="orta-yazi rounded shadow-lg d-flex">
      <p class="px-3 py-2 rounded"><b>Gence</b></p>
      <span class="px-3 py-2 rounded"><b>Mühendislik</b></span>
    </div>
  </div>
</template>

<script>
/*      <b-carousel-slide>
        <template #img>
          <img class="carousel-image-m" src="./images/carousel3.svg" alt="" />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img class="carousel-image-m" src="./images/carousel4.svg" alt="" />
        </template>
      </b-carousel-slide>*/
export default {
  name: "Carousel",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true;
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-image-m {
  object-fit: cover;
  width: 100%;
}
.orta-yazi {
  color: #fffffffa;
  background-color: rgba($color: #164098, $alpha: 0.9);
  position: absolute;
  top: 50%;
  left: 5%;
  font-weight: 800;

  span {
    font-weight: 300;
    color: rgba($color: #164098, $alpha: 0.9);
    background: rgba($color: #ffffff, $alpha: 0.95);
  }
}
@media screen and (min-width: 320px) {
  .carousel-image-m {
    height: 500px;
  }
  .orta-yazi {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 375px) {
  .carousel-image-m {
    height: 540px;
  }
  .orta-yazi {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 425px) {
  .carousel-image-m {
    height: 540px;
  }
  .orta-yazi {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .carousel-image-m {
    height: 700px;
  }
  .orta-yazi {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .carousel-image-m {
    height: 800px;
  }
  .orta-yazi {
    font-size: 3.5rem;
  }
}
//font-size: 5rem;
//height: 800px;
</style>
