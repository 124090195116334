<template>
  <!-- col-lg-4 col-md-6 -->
  <section id="solutions" class="container px-5 mt-5 rounded shadow">
    <h2 class="py-5">Çözümlerimiz:</h2>
    <div class="row">
      <div class="col-12 col-md-4 py-5">
        <img src="./images/data_process.svg" alt="" class="w-100" />
      </div>
      <div class="col-12 col-md-8 py-5">
        <details>
          <summary class=" py-3">
            Güvenlik
          </summary>
          <div class="sec-div py-5" style="color:#163f9889!important;">
            <div class="inner">
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">CCTV</h5>
                  </div>
                  <img
                    class="ml-3"
                    src="./images/security-camera.svg"
                    width="100px"
                  />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Yangın Alarm-Müdahale Sistemleri</h5>
                  </div>
                  <img class="ml-3" src="./images/fire.png" width="100px" />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Akıllı Geçiş Sistemleri</h5>
                  </div>
                  <img
                    class="ml-3"
                    src="./images/dial-keypad.svg"
                    width="100px"
                  />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Hırsız Alarm Sistemleri</h5>
                  </div>
                  <img class="ml-3" src="./images/siren.svg" width="100px" />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Ortam İzleme Sistemleri</h5>
                  </div>
                  <img class="ml-3" src="./images/monitor.svg" width="100px" />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Network Sistemleri</h5>
                  </div>
                  <img class="ml-3" src="./images/server.svg" width="100px" />
                </div>
              </div>
              <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">Akıllı Bina Sistemleri</h5>
                  </div>
                  <img
                    class="ml-3"
                    src="./images/smart-building.png"
                    width="100px"
                  />
                </div>
              </div>
            </div>
          </div>
        </details>
        <details>
          <summary class="py-3">
            Elektirik
          </summary>
          <div class="elec-div py-5">
            <div class="inner">
              <router-link to="/solutions/elec/0">
                <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">
                      Elektriksel Ölçüm ve Raporlama Hizmeti
                    </h5>
                  </div>
                  <img class="ml-3" src="./images/toprak.svg" width="100px" />
                </div>
              </div>
              </router-link>
              <router-link to="/solutions/elec/1">
                <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">
                      Elektrik Taahhüt
                    </h5>
                  </div>
                  <img class="ml-3" src="./images/tesisat.svg" width="100px" />
                </div>
              </div>
              </router-link>
              <router-link to="/solutions/elec/2">
                <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">
                      Proje Çizimi - Danışmanlık - Kontrollük Hizmetleri
                    </h5>
                  </div>
                  <img class="ml-3" src="./images/project.svg" width="100px" />
                </div>
              </div>
              </router-link>
              <router-link to="/solutions/elec/3">
                <div class="column-f p-3">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1">
                      İs Sağlığı ve Güvenliği
                    </h5>
                  </div>
                  <img class="ml-3" src="./images/isg.png" width="100px" />
                </div>
              </div>
              </router-link>
            </div>
          </div>
        </details>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "Solutions",
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  #solutions {
    transform: translate(0, -150px);
  }
}
.elec-div,.sec-div{
  h5{
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
#solutions {
  border-top: rgba($color: #164098, $alpha: 0.87) 4px solid;
  color: rgba($color: #164098, $alpha: 0.9);
  details {
    padding: 20px;
    summary {
      color: rgba($color: #164098, $alpha: 0.7);
      font-size: 1.6rem;
      &:focus {
        outline: none;
      }
      &::-webkit-details-marker {
        background: url("./images/expand.svg") center no-repeat;
        color: transparent;
        width: 17px;
        height: 17px;
      }
    }
  }
  details[open] summary::-webkit-details-marker {
    background: url("./images/collapse.svg") center no-repeat;
    color: transparent;
    width: 17px;
    height: 17px;
  }
  details[open] summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }
  @keyframes sweep {
    from {
      opacity: 0;
      margin-top: -10px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .column-f {
      color: rgba($color: #163f98, $alpha: 0.54) !important;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
