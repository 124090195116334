<template>
  <div>
    <div class="HeroWrapper">
      <Carousel/>
      <Pose/>
      <Solutions />
      <Certificets />
      <ReferansGallery/>
      <SplideBody />
    </div>
  </div>
</template>

<script>
import Solutions from './Solutions/Solutions.vue'
import Carousel from "./Carousel/Carousel.vue"
import Pose from "./Pose/Pose.vue"
import SplideBody from "./SplideBody/SplideBody.vue"
import Certificets from './Certificets/Certificets.vue'
import ReferansGallery from './ReferansGallery/ReferanceGallery'
export default {
  name:'HomeBody',
  components:{
    Carousel,
    Pose,
    Solutions,
    SplideBody,
    Certificets,
    ReferansGallery
  }
}
</script>

<style scoped>

</style>